import React from 'react';
import { withTheme } from 'emotion-theming';
import { Box, Stack, Button } from '@chakra-ui/core';
import PriorityEventsView from '@/src/views/priorityEvents';
import ActiveFlightsView from '@/src/views/activeFlights';
import ContentContainer from '@bit/matternet.shared.components.content-container';
import Head from 'next/head';
import { withApollo } from '../lib/apollo';
// import CommandsHotkeys from '../views/commands/CommandsHotkeys';
import openRequestedWindow from '../utils/openRequestedWindow';

const Index = () => {
  return (
    <>
      <Head>
        <title>Priority Events · Matternet Mission Control</title>
      </Head>
      <ContentContainer>
        {/* TODO: If restoring hotkeys, keep in mind access controls! */}
        {/* <CommandsHotkeys /> */}
        <Box>
          <Stack direction="row" mb="6">
            <Button
              variantColor="indigo"
              variant="outline"
              onClick={() => {
                openRequestedWindow(
                  `${window.location.origin}/vehicle`,
                  'vehicle',
                );
              }}
            >
              Aircraft Screen
            </Button>
            <Button
              variantColor="indigo"
              variant="outline"
              onClick={() => {
                openRequestedWindow(`${window.location.origin}/map`, 'map');
              }}
            >
              Map Screen
            </Button>
          </Stack>
          <Stack spacing={4} shouldWrapChildren>
            <PriorityEventsView />
            <ActiveFlightsView />
          </Stack>
        </Box>
      </ContentContainer>
    </>
  );
};

// @ts-ignore
export default withApollo({ ssr: false })(withTheme(Index));
