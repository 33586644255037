import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@chakra-ui/core';
import {
  faBatteryFull,
  faBatteryThreeQuarters,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryEmpty,
} from '@fortawesome/pro-duotone-svg-icons';

const BatteryFullIcon = () => <FontAwesomeIcon icon={faBatteryFull} />;
const BatteryThreeQuartersIcon = () => (
  <FontAwesomeIcon icon={faBatteryThreeQuarters} />
);
const BatteryHalfIcon = () => <FontAwesomeIcon icon={faBatteryHalf} />;
const BatteryQuarterIcon = () => <FontAwesomeIcon icon={faBatteryQuarter} />;
const BatteryEmptyIcon = () => <FontAwesomeIcon icon={faBatteryEmpty} />;

type DynamicBatteryIconProps = {
  /** State of charge for battery */
  percentage: number;
};

function percentageToColor(rawPercentage: number, maxHue = 120, minHue = 0) {
  const percentage = rawPercentage / 100;
  const hue = percentage * (maxHue - minHue) + minHue;
  return `hsl(${hue}, 100%, 50%)`;
}

const DynamicBatteryIcon = ({ percentage }: DynamicBatteryIconProps) => {
  const percentageColor = percentageToColor(percentage);

  const customStyle = {
    '--fa-primary-color': percentageColor,
    // Mapped to "gray.300"
    '--fa-secondary-color': percentage > 99 ? percentageColor : '#CBD5E0',
    '--fa-primary-opacity': 1,
    '--fa-secondary-opacity': 1,
  };

  // This gives the icon a percentage based color border
  const emptyCustomStyle = {
    '--fa-primary-color': percentageColor,
    '--fa-secondary-color': percentageColor,
    '--fa-primary-opacity': 1,
    // This increases the opacity as it approaches empty
    '--fa-secondary-opacity': (100 - percentage) / 100,
  };

  if (percentage < 25) {
    return (
      <Box color={percentageColor} style={emptyCustomStyle as any}>
        <BatteryEmptyIcon />
      </Box>
    );
  }

  if (percentage < 45) {
    return (
      <Box color={percentageColor} style={customStyle as any}>
        <BatteryQuarterIcon />
      </Box>
    );
  }

  if (percentage < 75) {
    return (
      <Box color={percentageColor} style={customStyle as any}>
        <BatteryHalfIcon />
      </Box>
    );
  }

  if (percentage < 95) {
    return (
      <Box color={percentageColor} style={customStyle as any}>
        <BatteryThreeQuartersIcon />
      </Box>
    );
  }

  return (
    <Box color={percentageColor} style={customStyle as any}>
      <BatteryFullIcon />
    </Box>
  );
};

export default DynamicBatteryIcon;
