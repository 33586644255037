import React from 'react';
import {
  useGetActivePriorityEventsSubscription,
  useSetSelectedFlightPlanMutation,
} from '@/generated/graphql';
import openRequestedWindow from '@/src/utils/openRequestedWindow';
import PriorityEvents from '@/src/components/PriorityEvents';
import { useSession } from 'next-auth/client';
import { Skeleton } from '@chakra-ui/core';
import parsePriorityEvents from './parsePriorityEvents';

const PriorityEventsView = () => {
  const [session] = useSession();

  const {
    data: importantPriorityEventsData,
    loading,
  } = useGetActivePriorityEventsSubscription();

  const [setSelectedFlightPlan] = useSetSelectedFlightPlanMutation({
    onCompleted: () => {
      openRequestedWindow(`${window.location.origin}/vehicle`, 'vehicle');
    },
  });

  const importantPriorityEvents =
    importantPriorityEventsData &&
    importantPriorityEventsData.vehiclePriorityEvents.length
      ? parsePriorityEvents(importantPriorityEventsData.vehiclePriorityEvents)
      : [];

  return (
    <Skeleton isLoaded={!loading}>
      <PriorityEvents
        data-testid="priority-events"
        data={importantPriorityEvents}
        onClick={(flightPlanId) => {
          setSelectedFlightPlan({
            variables: {
              auth0UserId: session.account?.id,
              flightPlanId,
            },
          });
        }}
      />
    </Skeleton>
  );
};

export default PriorityEventsView;
