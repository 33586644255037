/**
 * Logic to transform raw Hasura event response into expected data
 * for PriorityEvent component consumption
 */

import { GetActivePriorityEventsSubscription } from '@/generated/graphql';
import { PriorityEvent } from '@/src/components/PriorityEvents/types';

type RawPriorityEvents = GetActivePriorityEventsSubscription['vehiclePriorityEvents'];

const QUEUE_LENGTH = 3;

const tranformRawEvent = (
  priorityEvent: RawPriorityEvents[0],
): PriorityEvent => {
  const vehicleStatuses = priorityEvent?.vehicle?.vehicleStatuses;
  const estimatedSocPercentage =
    vehicleStatuses && vehicleStatuses.length > 0
      ? vehicleStatuses[0].estimatedSocPercentage
      : null;
  return {
    id: priorityEvent.id,
    vehicleId: priorityEvent.vehicle.vehicleId,
    vehicleName: priorityEvent.vehicle.name || 'NO VEHICLE NAME',
    batteryPercentage: estimatedSocPercentage || 0,
    eventMessage: priorityEvent.message,
    eventType: priorityEvent.flightStatusDetails?.flightStatus || 'NO STATUS',
    flightPlanId: priorityEvent.flightPlanId,
  };
};

const parsePriorityEvents = (
  priorityEvents: RawPriorityEvents,
): PriorityEvent[] => {
  const mostImportantEvents = priorityEvents
    .sort((priorityEventA, priorityEventB) => {
      return (
        priorityEventA.eventDetails.rank - priorityEventB.eventDetails.rank
      );
    })
    .slice(0, QUEUE_LENGTH)
    .map((priorityEvent) => {
      return tranformRawEvent(priorityEvent);
    });

  return mostImportantEvents;
};

export default parsePriorityEvents;
