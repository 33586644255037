import React from 'react';
import { Flex, PseudoBox, Text, Tag, Stack, BoxProps } from '@chakra-ui/core';
import { Card, CardContent } from '@bit/matternet.shared.components.card';
import { capitalCase } from 'change-case';
import { PriorityEventCardProps, PriorityEventCardCommonProps } from './types';
import DynamicBatteryIcon from '../shared/DynamicBatteryIcon';

const PriorityEventCard = ({
  vehicleName,
  eventType,
  eventMessage,
  batteryPercentage,
  onPriorityEventCardClick,
}: PriorityEventCardProps) => {
  return (
    <PseudoBox
      flex="1"
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
      _hover={{
        shadow: 'md',
        bg: 'indigo.50',
        borderColor: 'indigo.500',
      }}
      cursor="pointer"
      display="flex"
      overflow="hidden"
      rounded="lg"
      onClick={onPriorityEventCardClick}
      minHeight="15em"
    >
      <Card<BoxProps>
        flex="1"
        heading={<Text fontWeight="bold">{vehicleName}</Text>}
        display="flex"
        flexDirection="column"
      >
        <CardContent height="100%" flexDirection="column" display="flex">
          <Flex>
            <Text>{eventMessage}</Text>
          </Flex>

          <Flex justifyContent="space-between" marginTop="auto">
            <Tag size="sm" rounded="full" variantColor="green" px="3">
              <Text fontFamily="mono" fontSize="xs" px={2}>
                {capitalCase(eventType)}
              </Text>
            </Tag>
            <Stack spacing={2} isInline align="center" shouldWrapChildren>
              <DynamicBatteryIcon percentage={batteryPercentage} />
              <Text fontFamily="mono">{`${batteryPercentage}%`}</Text>
            </Stack>
          </Flex>
        </CardContent>
      </Card>
    </PseudoBox>
  );
};

export const PriorityEventPlaceholder = ({
  number,
}: PriorityEventCardCommonProps) => {
  return (
    <Flex
      flex={1}
      bg="gray.100"
      borderColor="gray.200"
      borderWidth="1px"
      minH="20"
      color="gray.200"
      px="6"
      justifyContent="center"
      alignItems="center"
      fontSize="10rem"
      overflow="hidden"
      rounded="lg"
      userSelect="none"
    >
      {number}
    </Flex>
  );
};

export default PriorityEventCard;
